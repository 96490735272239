// Gatsby supports TypeScript natively!
import React,{ useEffect,useState } from "react"
import Modal from "react-modal"
import { PageProps } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../../static/css/dataTables.bootstrap.css"
import "../../static/css/datatable-ajustes.css"
Modal.setAppElement("#___gatsby")
const jQuery = require("jquery")
const axios = require("axios")
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    // marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background:'#313444'
  }
};
const Publicaciones  = () => {
//   constructor(props){
//       super(props);
    //   state= {
    //       isModalOpen :false,
    //       mail:"",
    //       nombre:"",
    //       institucion:"",
    //       pais:"",
    //       puesto:"",
    //       subCobertura:false,
    //       subAnalisis:false,
    //       subApalache:false,
    //       subEstudios:false,
    //   }
//   }
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [pais, setPais] = useState("")
  const [institucion, setInstitucion] = useState("")
  const [puesto, setPuesto] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [subCobertura, setSubCobertura] = useState(false)
  const [subAnalisis, setSubAnalisis] = useState(false)
  const [subApalache, setSubApalache] = useState(false)
  const [subEstudios, setSubEstudios] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const send = async function(e){
    console.log("comenzando test")

    e.preventDefault()
    if (!executeRecaptcha) {
      console.log("no existe recaptcha")
      return
    }
    console.log("comenzando test 2")

    if (email.length === 0 || email.trim().length === 0) {
      console.log("no tienen valor  email")

      return
    } else if (nombre.length === 0 || nombre.trim().length === 0) {
      console.log("no tienen valor  nombre")

      return
    } 
    console.log("comenzando test 3")

    const result = await executeRecaptcha("contacto")
    const headers = {
      "Content-Type": "application/json"
    }
    console.log("comenzando test 4")

    const subject = "Subscripcion de usuario"
    const phone = ""
    const message = "El usuario "+getDatosUsuarios()+" desea subscribirse a "+getSubscriptionsMessage()
    console.log(message)

    axios
      .post(
        "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
        {
          name,
          email,
          message,
          subject,
          phone,
          token:result
        },
        {
          headers: headers
        }
      )
      .then(res => {
        jQuery("#contactform").slideUp("slow").hide()
        jQuery("#contactWrapper").html(
          "<div class=\"success\" style=\"color:white\"><h4>Subscripción creada correctamente!</h4><br><p>¡Gracias por usar nuestro formulario de subscripción! <br>  pronto nos pondremos en contacto con usted.</p></div>"
        )
      })
}
  const getSubscriptionsMessage = ()=>{
      var subscripciones = ""
        if(subCobertura){
            subscripciones+="Cobertura ";
        }
        if(subAnalisis){
            subscripciones+="Análisis ";
        }
        if(subApalache){
            subscripciones+="Apalache ESG";
        }
        if(subEstudios){
            subscripciones+="Estudios ";
        }

      return subscripciones ;
  }
  const getDatosUsuarios = ()=>{
      var datosUsuario = " nombre:"+nombre+"\n"
      datosUsuario += " email:"+email+"\n"
      datosUsuario += " pais:"+pais+"\n"
      datosUsuario += " institucion:"+institucion+"\n"
      datosUsuario += " puesto:"+puesto+"\n"


      return datosUsuario ;
  }
 
// useEffect(() => {
//   window.slider();
//   window.publicaciones();
// }, [])
const subscribePopup = (event,option) => {
    if(option==1){
      setSubCobertura(true)
    }
    if(option==2){
        setSubAnalisis(true)
    }
    if(option==3){
        setSubApalache(true)
    }
    if(option==4){
        setSubEstudios(true)
    }
    setIsModalOpen(true)
}
const handleCloseModal = event =>{
    setIsModalOpen(false)
}

// const setSubEstudios = (e) =>  {
//       setState({subEstudios:e.target.checked})
// }

    
return (
  <Layout title="Publicaciones" show_bread={true} >
    <SEO title="Publicaciones" />
    <div className="section mcb-section equal-height publicaciones" style={{paddingTop:'100px', paddingBottom:'60px' }} id="mainp">
        <div className={"section_wrapper mcb-section-inner"}>
            <div className={"wrap mcb-wrap one  valign-top clearfix"}>
                <div className={"row"}>
                    <div className={"column mcb-column one-second text-center"}>
                    <Link to="/publicacionesLists1">
                    <div style={{
                        padding: "15px ",
                        textAlign:"center",
                        border: "3px solid #2e313f",
                        margin: "0px 15%",
                        height: "190px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <h3 style={{width:"100%"}}>Coberturas y Análisis Financiero</h3>
                        </div>
                        </Link>
                        <button
                    className={"button  button_theme button_js"}
                    onClick={e => subscribePopup(e,1)}
                  >
                    <span className={"button_label"}>
                      Suscribirse
                    </span>
                  </button>
                    </div>

                    <div className={"column mcb-column one-second text-center"}>
                    <Link to="/publicacionesLists2">
                    <div style={{
                        padding: "15px ",
                        textAlign:"center",
                        border: "3px solid #2e313f",
                        margin: "0px 15%",
                        height: "190px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <h3 style={{width:"100%"}}>
                        Análisis macroeconómico
                            
                          
                      </h3>
                        </div>
                        </Link>
                        <button
                    className={"button  button_theme button_js"}
                    onClick={e => subscribePopup(e,2)}
                  >
                    <span className={"button_label"}>
                      Suscribirse
                    </span>
                  </button>
                    </div>
                    <div className={"column mcb-column one-second text-center"}>
                    <Link to="/publicacionesLists3">

                    <div style={{
                        padding: "15px ",
                        textAlign:"center",
                        border: "3px solid #2e313f",
                        margin: "0px 15%",
                        height: "190px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <h3 style={{width:"100%"}}>Apalache ESG</h3>
                        </div>
                        </Link>
                        <button
                    className={"button  button_theme button_js"}
                    onClick={e => subscribePopup(e,3)}
                  >
                    <span className={"button_label"}>
                      Suscribirse
                    </span>
                  </button>
                    </div>
                    <div className={"column mcb-column one-second text-center"}>
                    <Link to="/publicacionesLists4">

                    <div style={{
                        padding: "10px",
                        textAlign:"center",
                        border: "3px solid #2e313f",
                        margin: "0px 15%",
                        height: "190px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                        <h3 style={{width:"100%"}}>Estudios</h3>
                        </div>
                        </Link>
                        <button
                    className={"button  button_theme button_js"}
                    onClick={e => subscribePopup(e,4)}
                  >
                    <span className={"button_label"}>
                      Suscribirse
                    </span>
                  </button>
                    </div>
                </div>
            
               
            </div>
        </div>
    </div>
    <Modal contentLabel="Example modal" onRequestClose={e=>handleCloseModal(e)} isOpen={isModalOpen} style={customStyles}>
    <div id={"contactWrapper"}>    <h2 style={{ color:"white"}} >¿Qué contenido deseas recibir?</h2></div>

          <form
          autoComplete={"off"}
          id={"contactform"}
          onSubmit={e => send(e)}
        >
        <div className={"column one-second"}>
        <label style={{ color: "white" }}>
          <input
            type="checkbox"
            checked={subCobertura}
            onChange={e => setSubCobertura(e.target.checked)}
          />
          Cobertura
        </label>

      </div>
              <div className={"column one-second"}>
                  <label style={{ color: "white" }}>
                      <input
                          type="checkbox"
                          checked={subAnalisis}
                          onChange={e => setSubAnalisis(e.target.checked)}
                      />
                      Análisis
                  </label>

              </div>
              <div className={"column one-second"}>
                  <label style={{ color: "white" }}>
                      <input
                          type="checkbox"
                          checked={subApalache}
                          onChange={e => setSubApalache(e.target.checked)}
                      />
                      Apalache ESG
                  </label>

              </div>
              <div className={"column one-second"}>
                  <label style={{ color: "white" }}>
                      <input
                          type="checkbox"
                          checked={subEstudios}
                          onChange={e => setSubEstudios(e.target.checked)}
                      />
                      Estudios
                  </label>

              </div>
      
          <div className={"column one-second"}>
            <input
              onChange={e => setNombre(e.target.value)}
              required
              placeholder="Nombre"
              type="text"
              name="name"
              id="name"
              aria-required="true"
              aria-invalid="false"
            />
          </div>
          <div className={"column one-second"}>
            <input
              onChange={e => setEmail(e.target.value)}
              required
              placeholder="Correo"
              type="email"
              name="email"
              id="email"
              aria-required="true"
              aria-invalid="false"
            />
          </div>
          <div className={"column one-second"}>
            <input
              onChange={e => setInstitucion(e.target.value)}
              required
              placeholder="Institución"
              type="text"
              name="institucion"
              id="institucion"
              aria-invalid="false"
            />
          </div>
          <div className={"column one-second"}>
            <input
              onChange={e => setPais(e.target.value)}
              placeholder="País"
              type="text"
              name="pais"
              id="pais"
              aria-invalid="false"
            />
          </div>
          <div className={"column one-second"}>
              <input
              type="text"
                onChange={e => setPuesto(e.target.value)}
                required
                placeholder="Puesto"
                name="puesto"
                id="puesto"
                aria-invalid="false"
                />
          </div>
          <div className={"column one"}>
            <input type="submit" value="Enviar" id="submit"/>
          </div>
        </form>
    </Modal>
  </Layout>
)
}
  

export default Publicaciones
